<template>
	<div>
		<!-- 后台报表 -->
		<div class="queryBox mb20">
			<el-button size="mini" plain class="mr20" @click="setDate(-1)">前一天</el-button>
			<!-- <el-date-picker size="mini" v-model="date" type="date" placeholder="选择日期" class="mr20"></el-date-picker> -->
			<!-- <el-date-picker size="mini" v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="mr20"></el-date-picker> -->
			<el-date-picker size="mini" v-model="date" type="datetimerange" range-separator="至" start-placeholder="开始日期"
			 end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
			<el-button size="mini" plain class="mr20" @click="setDate(1)">后一天</el-button>
			<!-- <el-input size="mini" placeholder="请输入内容" v-model="input" clearable class="mr20" style="width: 200px"></el-input> -->
			<el-button size="mini" type="primary" icon="el-icon-search">搜索</el-button>
		</div>
		<template>
			<table class="dataTable"  width="100%"  v-loading="loading">
				<thead>
					<tr>
						<th>项目</th>
						<th>应有</th>
						<th>第三方</th>
						<th>公司</th>
						<th>实际</th>
						<th>代理</th>
						<th>商家</th>
						<th>利润</th>
					</tr>
				</thead>
				<tbody>
					
					<tr class="center" v-for="item in tableData"  v-if="tableData && tableData.length > 0">
						<td>{{item.name}}</td>
						<td>{{item.gold | tofixed}}</td>
						<td>{{item.fee_gold_adm | tofixed}}</td>
						<td>{{item.gold_adm | tofixed}}</td>
						<td>{{item.gold_in | tofixed}}</td>
						<td>{{item.gold_ag| tofixed}}</td>
						<td>{{item.gold_act | tofixed}}</td>
						<td></td>
					</tr>
					<tr class="nodata" v-if="!tableData || tableData.length === 0">
						<td colspan="8"> 暂无数据</td>
					</tr>
					<!-- <tr class="gap">
						<td colspan="8"></td>
					</tr> -->
					<tr class="center">
						<td>小计</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr class="center">
						<td>原有</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr class="center">
						<td>现有</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr class="center">
						<td>累计</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr class="center">
						<td rowspan="5">账变</td>
						<td>代付</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr class="center">
						<td>笔数</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr class="center">
						<td>手续费</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr class="center">
						<td>手续费</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr class="center">
						<td>现金账变</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					
				</tbody>
			</table>
		</template>
		<div class="flexX mt20">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				date: [],
				input: '',
				tableData: [],
				pageSizesArr:[20,50,100,200],
				pageSize:20,
				total:0,
				page:1
				
			}
		},
		created() {
			setTimeout(() => {
				this.loading = false
			}, 1000)
			this.date = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			setDate(day) {
				let data = this.date?this.date[0]:this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate + ' 00:00:00', newDate + ' 23:59:59']
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.page = 1
				this.pageSize = val
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page = val
			}
		}
	}
</script>

<style>
</style>
